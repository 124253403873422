import { TOKENS } from 'src/design';
import styled, { css } from 'styled-components';

export const TABLE_MAX_COL_SPAN = 99;
export const TABLE_MAX_ROW_SPAN = 99;

const TABLE_VARIOUS_CSS = {
  default: css`
    border-radius: 1rem;

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tr:nth-child(odd) {
      background-color: white;
    }
  `,
  border: css`
    background: white;

    border: 1px solid #ddd;

    td {
      border: 1px solid #ddd;
    }
  `,
};

type TableVarious = keyof typeof TABLE_VARIOUS_CSS;

type TableProps = {
  various?: TableVarious;
  height?: string;
};

export const Table = styled.table<TableProps>`
  height: ${(props) => props.height || '100%'};
  width: 100%;
  font-size: 1.2rem;
  overflow: hidden;
  box-shadow: ${TOKENS.shadow.default};
  text-align: left;
  border-collapse: collapse;

  th {
    padding: 1.2rem 1.6rem;
    background: #f1f1f1;
  }

  td {
    padding: 1.2rem 1.6rem;
  }

  ${(props) => TABLE_VARIOUS_CSS[props.various || 'default']}
`;
