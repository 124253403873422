import { PageTemplate } from 'src/components/Template';
import { Text, Spacing, Flex, FlexItem } from 'src/components/Layout';
import { ControlledSelect, ControlledSelectOption } from 'src/components/Form';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { useEffect, useState } from 'react';
import { UserBanner } from 'src/components/UserBanner';
import { ReportingCampaign } from './ReportingCampaign';
import { ReportingSites } from './ReportingSites';
import { ReportingAuctionResults } from './ReportingAuctionResults';
import {
  DateRange,
  DATE_RANGE_CUSTOM_OPTIONS,
  parseDateRange,
  REPORTING_PREVIOUS_PERIOD_OPTIONS,
  parsePreviousDateRange,
  PreviousPeriod,
} from 'src/utils';
import { ReportingCreatives } from './ReportingCreatives';
import { ReportingGeo } from './ReportingGEO';
import { ReportingApps } from './ReportingApps';
import { useReportingPersistentFilters, useRole } from 'src/hooks';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { ReportingPacing } from './ReportingPacing';
import { ReportingCampaignSelectInHouse } from 'src/components/ReportingCampaignSelect';
import { CampaignGroupSelect } from 'src/components/CampaignGroupSelect';
import { ReportingConversion } from './ReportingConversions';
import { ReportingAdExchange } from './ReportingAdExchange';

enum ReportingType {
  Campaigns = 'campaigns',
  Sites = 'sites',
  Apps = 'apps',
  Geo = 'geo',
  Creatives = 'creatives',
  AuctionResults = 'auction-results',
  Pacing = 'pacing',
  Conversion = 'conversion',
  AdExchange = 'ad-exchange',
}

export type ReportingFormValues = {
  type?: ReportingType;
  agencyId?: number;
  advertiserId?: number;
  campaignGroup?: string;
  campaignId?: number[];
  timeRange?: DateRange;
  dateFrom?: Date;
  dateTo?: Date;
  previousPeriod?: PreviousPeriod;
  customPeriodStart?: Date;
  customPeriodEnd?: Date;
  previousDateFrom?: Date;
  previousDateTo?: Date;
};

const REPORTING_TYPE_OPTIONS: ControlledSelectOption[] = [
  { label: 'Campaign', value: ReportingType.Campaigns },
  { label: 'Sites', value: ReportingType.Sites },
  { label: 'Apps', value: ReportingType.Apps },
  { label: 'Geo', value: ReportingType.Geo },
  { label: 'Creatives', value: ReportingType.Creatives },
  { label: 'Auction Results', value: ReportingType.AuctionResults },
  { label: 'Conversion', value: ReportingType.Conversion },
  { label: 'Ad Exchange', value: ReportingType.AdExchange },
];

const REPORTING_FORM_DEFAULT_VALUES: ReportingFormValues = {
  type: ReportingType.Campaigns,
  agencyId: null,
  advertiserId: null,
  campaignGroup: null,
  campaignId: [],
  timeRange: DateRange.Last7Days,
  previousPeriod: null,
};

export const Reporting = () => {
  const { canAccessAgency } = useRole();
  const { control, watch, setValue, reset } = useForm<ReportingFormValues>({
    defaultValues: REPORTING_FORM_DEFAULT_VALUES,
  });
  const values = watch();
  const [filter, setFilter] = useState<ReportingFormValues>(REPORTING_FORM_DEFAULT_VALUES);

  useReportingPersistentFilters({
    values: filter,
    setValues: (values) => {
      setFilter(values);
      reset(values);
    },
  });

  const onReportingTypeChange = (reportingType: ReportingType) => {
    setFilter({ ...filter, type: reportingType });
    reset({ ...filter, type: reportingType });
  };

  useEffect(() => {
    const { perviousDateFrom, perviousDateTo } = parsePreviousDateRange({
      previousPeriod: values.previousPeriod,
      customPeriodStart: values.customPeriodStart,
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
    });
    setValue('previousDateFrom', perviousDateFrom);
    setValue('previousDateTo', perviousDateTo);
    setValue('customPeriodEnd', perviousDateTo);
  }, [setValue, values.customPeriodStart, values.dateFrom, values.dateTo, values.previousPeriod]);

  const resetFilter = () => {
    setValue('campaignGroup', null);
    setValue('campaignId', []);
  };

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex gap="lg" align="center">
          <Text size="xxl" weight={700}>
            Reporting
          </Text>
          <ControlledSelect
            name="type"
            control={control}
            options={REPORTING_TYPE_OPTIONS}
            prefix="Type:"
            placeholder="Select type"
            width="22rem"
            onValueChange={onReportingTypeChange}
          />
        </Flex>
        <UserBanner />
      </Flex>
      <Spacing size="md" />
      <Flex width="100%" gap="md">
        {canAccessAgency && (
          <>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Agency</Text>
                <AgencySelect
                  name="agencyId"
                  control={control}
                  withAll
                  onValueChange={(val) => {
                    setFilter({ ...filter, agencyId: val, advertiserId: null, campaignGroup: null, campaignId: [] });
                    setValue('advertiserId', null);
                    resetFilter();
                  }}
                />
              </Flex>
            </FlexItem>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Advertiser</Text>
                <AdvertiserSelect
                  name="advertiserId"
                  control={control}
                  agencyId={values.agencyId}
                  withAll
                  onValueChange={(val) => {
                    setFilter({ ...filter, advertiserId: val, campaignGroup: null, campaignId: [] });
                    setValue('advertiserId', val);
                    resetFilter();
                  }}
                />
              </Flex>
            </FlexItem>
          </>
        )}
        <FlexItem grow={1}>
          <Flex direction="column" gap="sm">
            <Text size="xs">Campaign Group</Text>
            <CampaignGroupSelect
              name="campaignGroup"
              control={control}
              agencyId={values.agencyId}
              advertiserId={values.advertiserId}
              onValueChange={(val) => {
                setValue('campaignId', []);
                setFilter({ ...filter, campaignGroup: val, campaignId: [] });
              }}
              withAll
            />
          </Flex>
        </FlexItem>
        {values.type !== ReportingType.Pacing && (
          <>
            <FlexItem grow={1} maxWidth="30rem">
              <Flex direction="column" gap="sm">
                <Text size="xs">Campaign</Text>
                <ReportingCampaignSelectInHouse
                  name="campaignId"
                  control={control}
                  agencyId={values.agencyId}
                  advertiserId={values.advertiserId}
                  group={values.campaignGroup}
                  multiple
                  onValueChange={(val) => {
                    setFilter({ ...filter, campaignId: val });
                  }}
                />
              </Flex>
            </FlexItem>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Date Range</Text>
                <ControlledSelect
                  name="timeRange"
                  control={control}
                  options={DATE_RANGE_CUSTOM_OPTIONS}
                  onValueChange={(timeRange: DateRange) => {
                    const isCustom = timeRange === DateRange.Custom;
                    const selectedRange = isCustom ? DateRange.Last7Days : timeRange;
                    const { dateTo, dateFrom } = parseDateRange(selectedRange);

                    setValue('timeRange', timeRange);
                    if (!isCustom) {
                      setValue('dateTo', dateTo);
                      setValue('dateFrom', dateFrom);
                    }

                    setFilter({
                      ...filter,
                      timeRange,
                      dateFrom,
                      dateTo,
                    });
                  }}
                />
                {values.timeRange === DateRange.Custom && (
                  <Flex gap="sm" align="center">
                    <FlexItem grow={1}>
                      <ControlledDatePicker
                        name="dateFrom"
                        control={control}
                        placeholder="Date from"
                        onValueChange={(val) => {
                          setFilter({ ...filter, dateFrom: val });
                        }}
                      />
                    </FlexItem>
                    <FlexItem grow={1}>
                      <ControlledDatePicker
                        name="dateTo"
                        control={control}
                        placeholder="Date to"
                        onValueChange={(val) => {
                          setFilter({ ...filter, dateTo: val });
                        }}
                      />
                    </FlexItem>
                  </Flex>
                )}
              </Flex>
            </FlexItem>
            {[
              ReportingType.Campaigns,
              ReportingType.Creatives,
              ReportingType.Sites,
              ReportingType.Apps,
              ReportingType.Geo,
              ReportingType.AdExchange,
            ].includes(values.type) &&
              values.timeRange !== DateRange.AllTime && (
                <FlexItem grow={1}>
                  <Flex direction="column" gap="sm">
                    <Text size="xs">Previous period</Text>
                    <ControlledSelect
                      name="previousPeriod"
                      control={control}
                      options={REPORTING_PREVIOUS_PERIOD_OPTIONS}
                    />
                    {values.previousPeriod === PreviousPeriod.CustomPeriodStart && (
                      <Flex gap="sm">
                        <FlexItem>
                          <ControlledDatePicker name="customPeriodStart" control={control} placeholder="Period start" />
                        </FlexItem>
                        <FlexItem>
                          <ControlledDatePicker
                            name="customPeriodEnd"
                            control={control}
                            placeholder="Period end"
                            disabled
                          />
                        </FlexItem>
                      </Flex>
                    )}
                  </Flex>
                </FlexItem>
              )}
          </>
        )}
      </Flex>
      <Spacing size="lg" />
      {values.type === ReportingType.Campaigns && <ReportingCampaign {...filter} />}
      {values.type === ReportingType.Sites && <ReportingSites {...filter} />}
      {values.type === ReportingType.Apps && <ReportingApps {...filter} />}
      {values.type === ReportingType.Geo && <ReportingGeo {...filter} />}
      {values.type === ReportingType.Creatives && <ReportingCreatives {...filter} />}
      {values.type === ReportingType.AuctionResults && <ReportingAuctionResults {...filter} />}
      {values.type === ReportingType.Pacing && <ReportingPacing {...filter} />}
      {values.type === ReportingType.Conversion && <ReportingConversion {...filter} />}
      {values.type === ReportingType.AdExchange && <ReportingAdExchange {...filter} />}
    </PageTemplate>
  );
};
