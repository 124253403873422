import { Button } from 'src/components/Form';
import { Flex, FlexItem, Text } from 'src/components/Layout';

export enum DataTableExportType {
  Excel,
  Csv,
  Pdf,
}

type DataTableExportProps = {
  onExport: (type: DataTableExportType) => void;
  isLoading?: boolean;
};

export const DataTableExport = (props: DataTableExportProps) => {
  const { onExport, isLoading } = props;

  return (
    <FlexItem shrink={0}>
      <Flex gap="sm" align="center">
        <Text size="xs">Download as</Text>
        <Flex gap="sm">
          <Button size="xs" various="secondary" onClick={() => onExport(DataTableExportType.Csv)} isLoading={isLoading}>
            CSV
          </Button>
        </Flex>
      </Flex>
    </FlexItem>
  );
};
