import { Flex, FlexItem, Spacing } from 'src/components/Layout';
import { Table, Td, Th } from 'src/components/Table';
import { Fragment, ReactNode, useCallback } from 'react';
import { DataTableEmpty } from './DataTableEmpty';
import { DataTableError } from './DataTableError';
import { DataTableLoading } from './DataTableLoading';
import { DataTablePaginate } from './DataTablePaginate';
import { get } from 'lodash';
import { User } from 'src/types';
import { Icon } from '../Icon';
import { DataTableExportType } from './DataTableExport';
import { nanoid } from 'nanoid';
import styled, { css } from 'styled-components';

export type Column = {
  header?: ReactNode | (() => ReactNode);
  title?: string;
  accessor: string;
  render?: (value: any, row?: any, level?: number) => ReactNode;
  totalRender?: (data: any[]) => ReactNode;
  export?: ((value: any, row?: any) => any) | false;
  width?: string;
  sortable?: boolean;
  customizeGroup?: string;
  when?: (user?: User) => boolean;
};

export type Sort = {
  key?: string;
  direction?: 'asc' | 'desc';
};

export type DataTableRef = {
  export: (type: DataTableExportType, prefix: string) => void;
};

type DataTableProps = {
  idKey?: string;
  height?: string;
  columns?: Column[];
  visibleData?: any[];
  data?: any[];
  totalsData?: any[];
  isLoading?: boolean;
  error?: string | string[];
  total?: number;
  isPagination?: boolean;
  page?: number;
  setPage?: (page: number) => void;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  pageSize?: number;
  onPageChange?: (page: number) => void;
  sort?: Sort;
  onSortChange?: (sort: Sort) => void;
  enableTotal?: boolean;
  expendIds?: any[];
  totalPages?: number;
  expendData?: Record<any, any>;
  scroll?: boolean;
};

export const DEFAULT_PAGE = 1;
export const DEFAULT_TOTAL_PAGES = 0;
export const DEFAULT_PAGE_SIZE = 20;
export const MAX_PAGE_SIZE = 9999;

export const DataTable = (props: DataTableProps) => {
  const {
    idKey = 'id',
    columns,
    visibleData,
    totalPages = DEFAULT_TOTAL_PAGES,
    data,
    totalsData,
    isLoading,
    error,
    page,
    isPagination,
    pageSize = DEFAULT_PAGE_SIZE,
    onPageChange,
    setCurrentPage,
    sort,
    onSortChange,
    enableTotal,
    expendIds,
    expendData,
    scroll,
    height,
  } = props;

  const pageCount = Math.ceil(data.length / pageSize);

  const triggerSort = useCallback(
    (key: string) => {
      const newSort: Sort = {};
      if (sort?.key === key) {
        newSort.key = sort.key;
        newSort.direction = sort.direction === 'asc' ? 'desc' : 'asc';
      } else {
        newSort.key = key;
        newSort.direction = 'desc';
      }
      onSortChange(newSort);
    },
    [onSortChange, sort],
  );

  const renderRow = (row: any, level: number = 1) => {
    const id = get(row, idKey) ?? nanoid();
    return (
      <Fragment key={id}>
        <tr>
          {columns.map((column) => (
            <Td key={column.accessor} width={column.width}>
              {column.render ? column.render(get(row, column.accessor), row, level) : get(row, column.accessor)}
            </Td>
          ))}
        </tr>
        {expendIds?.includes(id) && expendData?.[id]?.map((row: any) => renderRow(row, level + 1))}
      </Fragment>
    );
  };

  const renderTotal = () => {
    const totals = isPagination ? totalsData : data;
    return (
      <tr>
        {columns.map((column) => (
          <Th key={column.accessor} width={column.width}>
            {column.totalRender ? column.totalRender(totals) : '-'}
          </Th>
        ))}
      </tr>
    );
  };

  return (
    <>
      <TableContainer scroll={scroll} className="data-hj-allow">
        <Table height={height}>
          <thead>
            <tr>
              {columns?.map((column) => (
                <Th
                  key={column.accessor}
                  title={column.title}
                  width={column.width}
                  sortable={column.sortable}
                  onClick={column.sortable ? () => triggerSort(column.accessor) : undefined}
                >
                  <Flex gap="xs" align="center">
                    <FlexItem>{typeof column.header === 'function' ? column.header() : column.header}</FlexItem>
                    {column.sortable && (
                      <FlexItem shrink={0}>
                        <Icon
                          size="sm"
                          color={column.accessor === sort?.key ? 'text' : 'gray'}
                          type={
                            column.accessor === sort?.key
                              ? sort?.direction === 'asc'
                                ? 'arrowUp'
                                : 'arrowDown'
                              : 'arrowDownUp'
                          }
                        />
                      </FlexItem>
                    )}
                  </Flex>
                </Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <DataTableLoading />
            ) : error ? (
              <DataTableError error={error} />
            ) : visibleData.length > 0 ? (
              <>
                {visibleData.map((row) => renderRow(row))}
                {enableTotal && renderTotal()}
              </>
            ) : (
              <DataTableEmpty />
            )}
          </tbody>
        </Table>
      </TableContainer>
      {isPagination
        ? !isLoading &&
          !error &&
          totalPages > 1 && (
            <>
              <Spacing size="xl" />
              <Flex justify="center">
                <DataTablePaginate
                  page={page}
                  pageCount={totalPages}
                  onPageChange={(page) => {
                    onPageChange(page);
                    setCurrentPage?.(page);
                  }}
                />
              </Flex>
            </>
          )
        : !isLoading &&
          !error &&
          pageCount > 1 && (
            <>
              <Spacing size="xl" />
              <Flex justify="center">
                <DataTablePaginate page={page} pageCount={pageCount} onPageChange={onPageChange} />
              </Flex>
            </>
          )}
    </>
  );
};

const TableContainer = styled.div<{ scroll?: boolean }>`
  ${(props) =>
    props.scroll &&
    css`
      max-width: 100%;
      overflow: auto;
    `}
`;
