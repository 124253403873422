import { Column } from 'src/components/DataTable';
import { DiffNumber } from './DiffNumber';
import { get, sumBy } from 'lodash';
import { formatAmount, formatAmountWithPrecision, formatCount, formatPercentage } from 'src/utils';

type GetReportingMetricsColumnsOptions = {
  isShowPrevious?: boolean;
  getPreviousData?: (row: any) => any;
  isWithMarkup?: boolean;
  isAdmin?: boolean;
};

export const getReportingMetricsColumns = (options?: GetReportingMetricsColumnsOptions): Column[] => {
  const { isShowPrevious, getPreviousData, isWithMarkup, isAdmin } = options;

  const getWithMarkupAccessor = (accessor: string) => {
    return isWithMarkup ? `${accessor}_markup` : isAdmin ? accessor : `${accessor}_our_markup`;
  };

  return [
    {
      header: 'Impressions',
      accessor: 'impressions',
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), 'impressions')}
          format={formatCount}
          isShowDiff={isShowPrevious}
        />
      ),
      export: formatCount,
      sortable: true,
      totalRender: (data) => {
        return formatCount(sumBy(data, 'impressions'));
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'Clicks',
      accessor: 'clicks',
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), 'clicks')}
          format={formatCount}
          isShowDiff={isShowPrevious}
        />
      ),
      export: formatCount,
      sortable: true,
      totalRender: (data) => {
        return formatCount(sumBy(data, 'clicks'));
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'CTR',
      accessor: 'ctr',
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), 'ctr')}
          format={formatPercentage}
          isShowDiff={isShowPrevious}
        />
      ),
      export: formatPercentage,
      sortable: true,
      totalRender: (data) => {
        const totalClicks = sumBy(data, 'clicks');
        if (!totalClicks) {
          return formatAmount(0);
        }
        const totalImpressions = sumBy(data, 'impressions');
        return formatPercentage((totalClicks / totalImpressions) * 100);
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'CPM',
      accessor: getWithMarkupAccessor('cpm'),
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), getWithMarkupAccessor('cpm'))}
          format={formatAmount}
          isShowDiff={isShowPrevious}
          isOpposite
        />
      ),
      export: formatAmount,
      sortable: true,
      totalRender: (data) => {
        const totalImpressions = sumBy(data, 'impressions');
        if (!totalImpressions) {
          return formatAmount(0);
        }
        const totalSpend = sumBy(data, getWithMarkupAccessor('total_spend'));
        return formatAmount((totalSpend / totalImpressions) * 1000);
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'CPC',
      accessor: getWithMarkupAccessor('cpc'),
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), getWithMarkupAccessor('cpc'))}
          format={formatAmount}
          isShowDiff={isShowPrevious}
          isOpposite
        />
      ),
      export: formatAmount,
      sortable: true,
      totalRender: (data) => {
        const totalClicks = sumBy(data, 'clicks');
        if (!totalClicks) {
          return formatAmount(0);
        }
        const totalSpend = sumBy(data, getWithMarkupAccessor('total_spend'));
        return formatAmount(totalSpend / totalClicks);
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'Conversions',
      accessor: 'total_conversions',
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), 'total_conversions')}
          format={formatCount}
          isShowDiff={isShowPrevious}
        />
      ),
      export: formatCount,
      sortable: true,
      totalRender: (data) => {
        return formatCount(sumBy(data, 'total_conversions'));
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'CPA',
      accessor: getWithMarkupAccessor('cpa'),
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), getWithMarkupAccessor('cpa'))}
          format={formatAmount}
          isShowDiff={isShowPrevious}
          isOpposite
        />
      ),
      export: formatAmount,
      sortable: true,
      totalRender: (data) => {
        const totalConversions = sumBy(data, 'total_conversions');
        if (!totalConversions) {
          return formatAmount(0);
        }
        const totalSpend = sumBy(data, getWithMarkupAccessor('total_spend'));
        return formatAmount(totalSpend / totalConversions);
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'Video/Audio Views',
      accessor: 'video_audio_views',
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), 'video_audio_views')}
          format={formatCount}
          isShowDiff={isShowPrevious}
        />
      ),
      export: formatCount,
      sortable: true,
      totalRender: (data) => {
        return formatCount(sumBy(data, 'video_audio_views'));
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'View Completed',
      accessor: 'video_audio_complete',
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), 'video_audio_complete')}
          format={formatCount}
          isShowDiff={isShowPrevious}
        />
      ),
      export: formatCount,
      sortable: true,
      totalRender: (data) => {
        return formatCount(sumBy(data, 'video_audio_complete'));
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'VCR',
      accessor: 'vcr',
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), 'vcr')}
          format={formatPercentage}
          isShowDiff={isShowPrevious}
        />
      ),
      export: formatPercentage,
      sortable: true,
      totalRender: (data) => {
        const totalVideoAudioViews = sumBy(data, 'video_audio_views');
        const totalVideoAudioComplete = sumBy(data, 'video_audio_complete');
        return formatPercentage(totalVideoAudioViews ? (totalVideoAudioComplete / totalVideoAudioViews) * 100 : 0);
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'CPV',
      accessor: getWithMarkupAccessor('cpv_video'),
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), getWithMarkupAccessor('cpv_video'))}
          format={(value) => formatAmountWithPrecision(value, 4)}
          isShowDiff={isShowPrevious}
        />
      ),
      export: (value) => formatAmountWithPrecision(value, 4),
      sortable: true,
      totalRender: (data) => {
        const totalSpend = sumBy(data, getWithMarkupAccessor('total_spend'));
        const totalVideoAudioViews = sumBy(data, 'video_audio_views');
        return formatAmountWithPrecision(totalVideoAudioViews ? totalSpend / totalVideoAudioViews : 0, 4);
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'CPCV',
      accessor: getWithMarkupAccessor('cpcv_video'),
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), getWithMarkupAccessor('cpv_video'))}
          format={(value) => formatAmountWithPrecision(value, 4)}
          isShowDiff={isShowPrevious}
        />
      ),
      export: (value) => formatAmountWithPrecision(value, 4),
      sortable: true,
      totalRender: (data) => {
        const totalSpend = sumBy(data, getWithMarkupAccessor('total_spend'));
        const totalVideoAudioComplete = sumBy(data, 'video_audio_complete');
        return formatAmountWithPrecision(totalVideoAudioComplete ? totalSpend / totalVideoAudioComplete : 0, 4);
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'Revenue',
      accessor: 'total_revenue',
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), 'total_revenue')}
          format={formatAmount}
          isShowDiff={isShowPrevious}
        />
      ),
      export: formatAmount,
      sortable: true,
      totalRender: (data) => {
        return formatAmount(sumBy(data, 'total_revenue'));
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'ROAS',
      accessor: getWithMarkupAccessor('roas'),
      render: (value, row) => (
        <DiffNumber
          current={value * 100}
          previous={get(getPreviousData?.(row), getWithMarkupAccessor('roas'))}
          format={formatPercentage}
          isShowDiff={isShowPrevious}
        />
      ),
      export: formatPercentage,
      sortable: true,
      totalRender: (data) => {
        const totalRevenue = sumBy(data, 'total_revenue');
        const totalSpend = sumBy(data, getWithMarkupAccessor('total_spend'));
        return formatPercentage((totalRevenue / totalSpend) * 100);
      },
      customizeGroup: 'Reporting Metrics',
    },
    {
      header: 'Spend',
      accessor: getWithMarkupAccessor('total_spend'),
      render: (value, row) => (
        <DiffNumber
          current={value}
          previous={get(getPreviousData?.(row), getWithMarkupAccessor('total_spend'))}
          format={formatAmount}
          isShowDiff={isShowPrevious}
        />
      ),
      export: formatAmount,
      sortable: true,
      totalRender: (data) => {
        return formatAmount(sumBy(data, getWithMarkupAccessor('total_spend')));
      },
      customizeGroup: 'Reporting Metrics',
      when: () => !isAdmin,
    },
    {
      header: 'Markup',
      accessor: '_markup',
      render: (_, row) => formatAmount(row.total_spend_our_markup - row.total_spend),
      totalRender: (data) => {
        return formatAmount(sumBy(data, 'total_spend_our_markup') - sumBy(data, 'total_spend'));
      },
      export: formatAmount,
      customizeGroup: 'Reporting Metrics',
      when: () => isAdmin,
    },
    {
      header: 'WL Markup',
      accessor: '_wl_markup',
      render: (_, row) => formatAmount(row.total_spend_markup - row.total_spend_our_markup),
      totalRender: (data) => {
        return formatAmount(sumBy(data, 'total_spend_markup') - sumBy(data, 'total_spend_our_markup'));
      },
      export: formatAmount,
      customizeGroup: 'Reporting Metrics',
      when: () => isAdmin,
    },
    {
      header: 'DSP Spend',
      accessor: 'total_spend',
      render: formatAmount,
      totalRender: (data) => {
        return formatAmount(sumBy(data, 'total_spend'));
      },
      export: formatAmount,
      sortable: true,
      customizeGroup: 'Reporting Metrics',
      when: () => isAdmin,
    },
    {
      header: 'Total Spend',
      accessor: 'total_spend_markup',
      render: formatAmount,
      totalRender: (data) => {
        return formatAmount(sumBy(data, 'total_spend_markup'));
      },
      export: formatAmount,
      sortable: true,
      customizeGroup: 'Reporting Metrics',
      when: () => isAdmin,
    },
  ];
};
