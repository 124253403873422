import { useMemo } from 'react';
import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { sortAlphabetically } from 'src/utils';

const { useCampaignGroupsQuery } = growthApi;

type CampaignGroupSelectProps<T> = ControlledSelectProps<T> & {
  agencyId?: number;
  advertiserId?: number;
  withAll?: boolean;
};

export const CampaignGroupSelect = <T,>(props: CampaignGroupSelectProps<T>) => {
  const { agencyId, advertiserId, withAll, ...rest } = props;

  const { data, isLoading } = useCampaignGroupsQuery(
    {
      agency_id: agencyId,
      advertiser_id: advertiserId,
    },
    { skip: !agencyId || !advertiserId },
  );

  const options = useMemo(() => {
    return withAll ? [{ label: 'All', value: null }, ...(data?.data || [])] : data?.data || [];
  }, [data?.data, withAll]);

  return (
    <ControlledSelect
      isLoading={isLoading}
      options={sortAlphabetically(options)}
      placeholder="Select campaign group"
      {...rest}
    />
  );
};
