import { ControlledSelectOption } from 'src/components/Form';
import {
  addDays,
  differenceInDays,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

export enum DateRange {
  Yesterday = 'yesterday',
  Today = 'today',
  Last7Days = '7',
  Last30Days = '30',
  Last90Days = '90',
  LastMonth = 'last_month',
  ThisMonth = 'this_month',
  LastWeek = 'last_week',
  ThisWeek = 'this_week',
  AllTime = 'all_time',
  Custom = 'custom',
}

export enum PreviousPeriod {
  PreviousPeriod,
  PreviousYear,
  CustomPeriodStart,
}

export const DATE_RANGE_OPTIONS: ControlledSelectOption[] = [
  {
    label: 'Yesterday',
    value: DateRange.Yesterday,
  },
  {
    label: 'Today',
    value: DateRange.Today,
  },
  {
    label: '7 Days',
    value: DateRange.Last7Days,
  },
  {
    label: '30 Days',
    value: DateRange.Last30Days,
  },
  {
    label: '90 Days',
    value: DateRange.Last90Days,
  },
  {
    label: 'Last Month',
    value: DateRange.LastMonth,
  },
  {
    label: 'This Month',
    value: DateRange.ThisMonth,
  },
  {
    label: 'Last Week',
    value: DateRange.LastWeek,
  },
  {
    label: 'This Week',
    value: DateRange.ThisWeek,
  },
  {
    label: 'All Time',
    value: DateRange.AllTime,
  },
];

export const DATE_RANGE_CUSTOM_OPTIONS: ControlledSelectOption[] = [
  ...DATE_RANGE_OPTIONS,
  {
    label: 'Custom',
    value: DateRange.Custom,
  },
];

export const REPORTING_PREVIOUS_PERIOD_OPTIONS: ControlledSelectOption[] = [
  { label: 'None', value: null },
  { label: 'Previous period', value: PreviousPeriod.PreviousPeriod },
  { label: 'Previous year', value: PreviousPeriod.PreviousYear },
  { label: 'Custom period start', value: PreviousPeriod.CustomPeriodStart },
];

export const parseDateRange = (dateRange: DateRange) => {
  const today = new Date();
  const yesterday = subDays(today, 1);
  switch (dateRange) {
    case DateRange.Yesterday: {
      return {
        dateTo: yesterday,
        dateFrom: yesterday,
      };
    }
    case DateRange.Today: {
      return {
        dateTo: today,
        dateFrom: today,
      };
    }
    case DateRange.Last7Days: {
      return {
        dateTo: today,
        dateFrom: subDays(today, 7),
      };
    }
    case DateRange.Last30Days: {
      return {
        dateTo: today,
        dateFrom: subDays(today, 30),
      };
    }
    case DateRange.Last90Days: {
      return {
        dateTo: today,
        dateFrom: subDays(today, 90),
      };
    }
    case DateRange.LastMonth: {
      return {
        dateFrom: startOfMonth(subMonths(today, 1)),
        dateTo: endOfMonth(subMonths(today, 1)),
      };
    }
    case DateRange.ThisMonth: {
      return {
        dateFrom: startOfMonth(today),
        dateTo: today,
      };
    }
    case DateRange.LastWeek: {
      return {
        dateFrom: startOfWeek(subWeeks(today, 1)),
        dateTo: endOfWeek(subWeeks(today, 1)),
      };
    }
    case DateRange.ThisWeek: {
      return {
        dateFrom: startOfWeek(today),
        dateTo: today,
      };
    }
    case DateRange.AllTime: {
      return {
        dateTo: undefined,
        dateFrom: undefined,
      };
    }
  }
};

export const parsePreviousDateRange = (options: {
  previousPeriod: PreviousPeriod;
  customPeriodStart?: Date;
  dateFrom?: Date;
  dateTo?: Date;
}) => {
  const result: {
    perviousDateFrom?: Date;
    perviousDateTo?: Date;
  } = {
    perviousDateFrom: null,
    perviousDateTo: null,
  };

  if (!options.dateFrom || !options.dateTo) {
    return result;
  }

  switch (options.previousPeriod) {
    case PreviousPeriod.CustomPeriodStart:
      if (!options.customPeriodStart) {
        return result;
      }
      result.perviousDateFrom = options.customPeriodStart;
      result.perviousDateTo = addDays(result.perviousDateFrom, differenceInDays(options.dateTo, options.dateFrom));
      break;
    case PreviousPeriod.PreviousPeriod:
      result.perviousDateTo = subDays(options.dateFrom, 1);
      result.perviousDateFrom = subDays(result.perviousDateTo, differenceInDays(options.dateTo, options.dateFrom));
      break;
    case PreviousPeriod.PreviousYear:
      result.perviousDateFrom = subYears(options.dateFrom, 1);
      result.perviousDateTo = subYears(options.dateTo, 1);
      break;
  }

  return result;
};

export const TIMEZONE_OPTIONS: ControlledSelectOption[] = [
  {
    label: '(GMT -11:00) Midway Island, Samoa',
    value: 2,
  },
  {
    label: '(GMT -10:00) Hawaii',
    value: 3,
  },
  {
    label: '(GMT -9:00) Alaska',
    value: 4,
  },
  {
    label: '(GMT -8:00) Pacific Time (US & Canada)',
    value: 5,
  },
  {
    label: '(GMT -7:00) Mountain Time (US & Canada)',
    value: 6,
  },
  {
    label: '(GMT -6:00) Central Time (US & Canada), Mexico City',
    value: 7,
  },
  {
    label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
    value: 8,
  },
  {
    label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
    value: 9,
  },
  {
    label: '(GMT -3:30) Newfoundland',
    value: 10,
  },
  {
    label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
    value: 11,
  },
  {
    label: '(GMT -2:00) Mid-Atlantic',
    value: 12,
  },
  {
    label: '(GMT -1:00) Azores, Cape Verde Islands',
    value: 13,
  },
  {
    label: '(GMT) Greenwich Mean Time (no daylight savings)',
    value: 14,
  },
  {
    label: '(GMT) London, Lisbon, Casablanca',
    value: 15,
  },
  {
    label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
    value: 16,
  },
  {
    label: '(GMT +2:00) Kaliningrad, South Africa',
    value: 17,
  },
  {
    label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    value: 18,
  },
  {
    label: '(GMT +3:30) Tehran',
    value: 19,
  },
  {
    label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
    value: 20,
  },
  {
    label: '(GMT +4:30) Kabul',
    value: 21,
  },
  {
    label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    value: 22,
  },
  {
    label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
    value: 23,
  },
  {
    label: '(GMT +5:45) Kathmandu',
    value: 24,
  },
  {
    label: '(GMT +6:00) Almaty, Dhaka, Colombo',
    value: 25,
  },
  {
    label: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
    value: 26,
  },
  {
    label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
    value: 27,
  },
  {
    label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    value: 28,
  },
  {
    label: '(GMT +9:30) Adelaide, Darwin',
    value: 29,
  },
  {
    label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
    value: 30,
  },
  {
    label: '(GMT +11:00) Pohnpei, Solomon Islands, New Caledonia',
    value: 31,
  },
  {
    label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
    value: 32,
  },
];

export const GEO_OPTIONS: ControlledSelectOption[] = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'APAC', label: 'Asia-Pacific' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BR', label: 'Brazil' },
  { value: 'BN', label: 'Brunei' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo (DRC)' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: "Côte d'Ivoire" },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'EU', label: 'Europe' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'Global', label: 'Global' },
  { value: 'GR', label: 'Greece' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KR', label: 'South Korea' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LATAM', label: 'Latin America & Caribbean' },
  { value: 'LA', label: 'Laos' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MENA', label: 'Middle East & North Africa' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'North America' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russia' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SZ', label: 'Eswatini' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syria' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TG', label: 'Togo' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];
export const AUDIENCE_SIZE_OPTIONS: ControlledSelectOption[] = [
  { label: '0 - 10,000,000', value: '0-10000000' },
  { label: '10,000,000 - 50,000,000', value: '10000000-50000000' },
  { label: '50,000,000 - 100,000,000', value: '50000000-100000000' },
  { label: '100,000,000 - 200,000,000', value: '100000000-200000000' },
  { label: '300,000,000 - 400,000,000', value: '300000000-400000000' },
  { label: '400,000,000 - 500,000,000', value: '400000000-500000000' },
  { label: '500,000,000+', value: '500000000' },
];
export const PRICE_OPTIONS: ControlledSelectOption[] = [
  { label: '$0.00 - $2.99', value: '0-2.99' },
  { label: '$3.00 - $4.00', value: '3-4' },
  { label: '$4.01 - $5.00', value: '4.01-5' },
  { label: '$5.01 - $10.00', value: '5.01-10' },
  { label: '$10.00+', value: '10.01-100' },
];
