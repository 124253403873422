import { ControlledInput } from 'src/components/Form/ControlledInput';
import { Form, FormItem } from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import { nanoid } from 'nanoid';
import { Flex, FlexItem, Text } from '../Layout';
import { ControlledDatePicker } from '../Form/ControlledDatePicker';
import { Icon } from '../Icon';
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';

const {
  useUpdateAgencyExtMutation,
  useUpdateAdvertiserExtMutation,
  useCreateOrUpdateCampaignMutation,
  useMarkupMappingsMutation,
} = growthApi;

type Markups = {
  id: string;
  start_date?: Date;
  end_date?: Date;
  markup?: number;
}[];

type EditMarkupsModalProps = {
  type: 'agency' | 'advertiser' | 'advertiser_wl' | 'campaign';
  agency?: any;
  advertiser?: any;
  campaign?: any;
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
};

const MODAL_TITLE = {
  campaign: 'Edit Campaign Markup',
  agency: 'Edit Default Markup',
  advertiser: 'Edit Our Markup',
  advertiser_wl: 'Edit WL Markup',
};

type EditMarkupsFormValues = {
  markups?: Markups;
};

const DEFAULT_MARKUPS: Markups = [
  {
    id: nanoid(),
  },
];

const parseMarkups = (markups?: Markups) => {
  return (
    markups?.map((markup) => ({
      ...markup,
      start_date: markup.start_date ? new Date(markup.start_date) : undefined,
      end_date: markup.end_date ? new Date(markup.end_date) : undefined,
    })) || DEFAULT_MARKUPS
  );
};

const markupsToString = (markups?: Markups) => {
  return markups?.map((markup) => ({
    ...markup,
    start_date: markup.start_date ? format(markup.start_date, 'yyyy-MM-dd') : undefined,
    end_date: markup.end_date ? format(markup.end_date, 'yyyy-MM-dd') : undefined,
  }));
};

export const EditMarkupsModal = (props: EditMarkupsModalProps) => {
  const { isOpen, type, agency, advertiser, campaign, onSuccess, onClose } = props;

  const { watch, setValue, handleSubmit, control, reset } = useForm<EditMarkupsFormValues>();
  const values = watch();
  const [updateAgencyExt, { isLoading: isUpdateAgencyExtLoading }] = useUpdateAgencyExtMutation();
  const [updateAdvertiserExt, { isLoading: isUpdateAdvertiserExtLoading }] = useUpdateAdvertiserExtMutation();
  const [updateCampaign, { isLoading: isUpdateCampaignLoading }] = useCreateOrUpdateCampaignMutation();
  const [markupMapping, { isLoading: isUpdateMarkupLoading }] = useMarkupMappingsMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const isModalLoading =
    isUpdateAgencyExtLoading || isUpdateAdvertiserExtLoading || isUpdateCampaignLoading || isUpdateMarkupLoading;

  useEffect(() => {
    if (isOpen) {
      let markups = DEFAULT_MARKUPS;
      switch (type) {
        case 'agency':
          markups = parseMarkups(agency?.markups);
          break;
        case 'advertiser':
          markups = parseMarkups(advertiser?.markups);
          break;
        case 'advertiser_wl':
          markups = parseMarkups(advertiser?.markups_wl);
          break;
        case 'campaign':
          markups = parseMarkups(campaign?.markups);
      }
      reset({
        markups,
      });
    }
  }, [isOpen, reset, type, agency, advertiser, campaign]);

  const onAddMarkup = (index: number) => {
    const markups = cloneDeep(values.markups);
    markups.splice(index + 1, 0, { id: nanoid() });
    setValue('markups', markups);
  };

  const onRemoveMarkup = (index: number) => {
    setValue(
      'markups',
      values.markups.filter((_, flightIndex) => flightIndex !== index),
    );
  };

  const onSubmit = async (values: EditMarkupsFormValues) => {
    try {
      switch (type) {
        case 'agency':
          await updateAgencyExt({
            markups: markupsToString(values.markups),
            agency_id: agency?.id,
          }).unwrap();
          break;
        case 'advertiser':
          await updateAdvertiserExt({
            markups: markupsToString(values.markups),
            advertiser_id: advertiser?.id,
          }).unwrap();
          break;
        case 'advertiser_wl':
          await updateAdvertiserExt({
            markups_wl: markupsToString(values.markups),
            advertiser_id: advertiser?.id,
          }).unwrap();
          break;
        case 'campaign':
          await updateCampaign({
            markups: markupsToString(values.markups),
            agency_id: campaign?.agency_id,
            advertiser_id: campaign?.advertiser_id,
            campaign_name: campaign?.campaign_name,
            id: campaign?.id,
          }).unwrap();
          break;
      }
      await markupMapping({
        type,
        id: agency?.id ?? advertiser?.id ?? campaign?.id,
        markups: markupsToString(values.markups),
      }).unwrap();
      showSuccessToast('Save markup successfully');
      onClose();
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={MODAL_TITLE[type]}
      isLoading={isModalLoading}
      onConfirm={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Form>
        {type === 'campaign' && (
          <FormItem label="Campaign">
            <Text size="sm">
              {campaign?.campaign_name} - {campaign?.id}
            </Text>
          </FormItem>
        )}
        <FormItem label="Markups">
          <Flex direction="column" gap="md">
            {values.markups?.map((markup, index) => (
              <Flex key={markup.id} gap="md" align="center">
                <ControlledDatePicker name={`markups.${index}.start_date`} control={control} placeholder="Start date" />
                <ControlledDatePicker name={`markups.${index}.end_date`} control={control} placeholder="End date" />
                <ControlledInput
                  name={`markups.${index}.markup`}
                  control={control}
                  placeholder="Markup"
                  type="number"
                  suffix="%"
                />
                <FlexItem width="24rem">
                  <Flex gap="sm" align="center">
                    <Icon type="plus" color="primary" onClick={() => onAddMarkup(index)} clickable />
                    {index !== 0 && <Icon type="trash" color="error" onClick={() => onRemoveMarkup(index)} clickable />}
                  </Flex>
                </FlexItem>
              </Flex>
            ))}
          </Flex>
        </FormItem>
      </Form>
    </Modal>
  );
};
