import { format } from 'date-fns';
import { get, set } from 'lodash';

export const transformNumber = (value: any) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  return Number(value);
};

export const transformNumberArray = (value: any) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  if (typeof value === 'string') {
    return value.split('|').map(Number);
  }
  return value.map(Number);
};

export const transformDate = (value: any) => {
  if (!value) {
    return undefined;
  }
  return new Date(value);
};

export enum TransformType {
  Number,
  NumberArray,
  Date,
  None,
}

type TransformParams = {
  [path: string]: TransformType;
};

export const transformObject = (source: object, transformParams: TransformParams) => {
  const result: any = {};
  Object.entries(transformParams).forEach(([path, type]) => {
    let value = get(source, path);
    switch (type) {
      case TransformType.Number:
        value = transformNumber(value);
        break;
      case TransformType.NumberArray:
        value = transformNumberArray(value);
        break;
      case TransformType.Date:
        value = transformDate(value);
        break;
    }
    set(result, path, value);
  });
  return result;
};

export const stringToDate = (value?: string) => {
  if (!value) {
    return undefined;
  }
  return new Date(value);
};

export const dateToString = (value?: Date) => {
  if (!value) {
    return null;
  }
  return format(value, 'yyyy-MM-dd HH:mm:ss');
};
export const fotmatDate = (value?: Date) => {
  if (!value) {
    return null;
  }
  return format(value, 'MM/dd/yy');
};
export const sortAlphabetically = <T extends { label: string; value: any }>(arr: any): T[] => {
  return arr
    .map((item) => ({
      ...item,
      sortKey: item.label.replace(/^[^a-zA-Z]*/, '').toLowerCase(),
      hasLetters: /[a-zA-Z]/.test(item.label),
      isAll: item.label === 'All',
    }))
    .sort((a, b) => {
      if (a.isAll) return -1;
      if (b.isAll) return 1;
      if (a.hasLetters && !b.hasLetters) return -1;
      if (!a.hasLetters && b.hasLetters) return 1;
      return a.sortKey.localeCompare(b.sortKey);
    })
    .map(({ sortKey, hasLetters, isAll, ...rest }) => rest as unknown as T);
};
