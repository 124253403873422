import { Button, ControlledSelect } from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Flex, FlexItem, Grid, Spacing, Text } from 'src/components/Layout';
import { Loading } from 'src/components/Loading';
import { PageTemplate } from 'src/components/Template';
import { UserBanner } from 'src/components/UserBanner';
import { TOKENS } from 'src/design';
import { getUserName } from 'src/helpers';
import { Link } from 'react-router-dom';
import { growthApi } from 'src/services';
import { useAppSelector } from 'src/store';
import styled from 'styled-components';
import { DateRange, getApiErrorMessage, imageUrl, parseDateRange, DATE_RANGE_CUSTOM_OPTIONS } from 'src/utils';
import { useForm } from 'react-hook-form';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { ChartSummary } from './ChartSummary';
import { ChartSites } from './ChartSites';
import { ChartCreatives } from './ChartCreatives';
import { useReportingPersistentFilters, useRole, useWhiteLabelTheme } from 'src/hooks';
import { Error } from 'src/components/Error';
import { AgencySelect } from 'src/components/AgencySelect';
import { ReportingInfo } from 'src/components/ReportingInfo';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { ReportingCampaignSelectInHouse } from 'src/components/ReportingCampaignSelect';
import { CampaignGroupSelect } from 'src/components/CampaignGroupSelect';
import { useState } from 'react';

enum OverviewAccountSetup {
  SetupPixels,
  CreateAudiences,
  UploadCreatives,
  CreateYourFirstCampaign,
  ConnectBilling,
  TrackPerformance,
}

const OVERVIEW_ACCOUNT_SETUPS = [
  {
    label: 'Set up Pixels',
    value: OverviewAccountSetup.SetupPixels,
    url: '/activate/pixels/new',
  },
  {
    label: 'Create Audiences',
    value: OverviewAccountSetup.CreateAudiences,
    url: '/activate/audiences/new',
  },
  {
    label: 'Upload Creatives',
    value: OverviewAccountSetup.UploadCreatives,
    url: '/activate/creatives/new',
  },
  {
    label: 'Create your first Campaign',
    value: OverviewAccountSetup.CreateYourFirstCampaign,
    url: '/activate/campaigns/new',
  },
  {
    label: 'Connect billing',
    value: OverviewAccountSetup.ConnectBilling,
    url: '/settings/ad-wallet',
  },
  {
    label: 'Track performance',
    value: OverviewAccountSetup.TrackPerformance,
    url: '/activate/reporting',
  },
];

const { useCampaignsQuery } = growthApi;

export type OverviewFormValues = {
  agencyId?: number;
  advertiserId?: number;
  campaignGroup?: string;
  campaignId?: number[];
  timeRange?: DateRange;
  dateFrom?: Date;
  dateTo?: Date;
};

const OVERVIEW_FORM_DEFAULT_VALUES: OverviewFormValues = {
  agencyId: null,
  advertiserId: null,
  campaignGroup: null,
  campaignId: [],
  timeRange: DateRange.Last7Days,
};

export const Overview = () => {
  const { isAdmin, canAccessAgency, isViewOnly } = useRole();
  const user = useAppSelector((state) => state.user.user);
  const theme = useWhiteLabelTheme();
  const { watch, control, setValue, reset } = useForm<OverviewFormValues>({
    defaultValues: OVERVIEW_FORM_DEFAULT_VALUES,
  });
  const values = watch();
  const [filter, setFilter] = useState<OverviewFormValues>(OVERVIEW_FORM_DEFAULT_VALUES);

  useReportingPersistentFilters({
    values: filter,
    setValues: (values) => {
      setFilter(values);
      reset(values);
    },
  });

  const isNotSelectAgencyAdvertiser = !isAdmin && (!values.agencyId || !values.advertiserId);

  const { data, isFetching, error } = useCampaignsQuery(
    {
      agency_id: filter.agencyId,
      advertiser_id: filter.advertiserId,
    },
    {
      skip: isNotSelectAgencyAdvertiser,
    },
  );

  const resetFilter = () => {
    setValue('campaignGroup', null);
    setValue('campaignId', []);
  };

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Overview
          </Text>
        </Flex>
        <UserBanner />
      </Flex>
      <Spacing size="md" />
      <Flex width="100%" gap="md">
        {canAccessAgency && (
          <>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Agency</Text>
                <AgencySelect
                  name="agencyId"
                  control={control}
                  withAll
                  onValueChange={(val) => {
                    setFilter({ ...filter, agencyId: val, advertiserId: null, campaignGroup: null, campaignId: [] });
                    setValue('advertiserId', null);
                    resetFilter();
                  }}
                />
              </Flex>
            </FlexItem>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Advertiser</Text>
                <AdvertiserSelect
                  name="advertiserId"
                  control={control}
                  agencyId={values.agencyId}
                  withAll
                  onValueChange={(val) => {
                    setFilter({ ...filter, advertiserId: val, campaignGroup: null, campaignId: [] });
                    resetFilter();
                  }}
                />
              </Flex>
            </FlexItem>
          </>
        )}
        <FlexItem grow={1}>
          <Flex direction="column" gap="sm">
            <Text size="xs">Campaign Group</Text>
            <CampaignGroupSelect
              name="campaignGroup"
              control={control}
              agencyId={values.agencyId}
              advertiserId={values.advertiserId}
              onValueChange={(val) => {
                setValue('campaignId', []);
                setFilter({ ...filter, campaignGroup: val, campaignId: [] });
              }}
              withAll
            />
          </Flex>
        </FlexItem>
        <FlexItem grow={1} maxWidth="30rem">
          <Flex direction="column" gap="sm">
            <Text size="xs">Campaign</Text>
            <ReportingCampaignSelectInHouse
              name="campaignId"
              control={control}
              agencyId={values.agencyId}
              advertiserId={values.advertiserId}
              group={values.campaignGroup}
              multiple
              onValueChange={(val) => {
                setFilter({ ...filter, campaignId: val });
              }}
            />
          </Flex>
        </FlexItem>
        <FlexItem grow={1}>
          <Flex direction="column" gap="sm">
            <Text size="xs">Date Range</Text>
            <ControlledSelect
              name="timeRange"
              control={control}
              options={DATE_RANGE_CUSTOM_OPTIONS}
              onValueChange={(timeRange: DateRange) => {
                const isCustom = timeRange === DateRange.Custom;
                const selectedRange = isCustom ? DateRange.Last7Days : timeRange;
                const { dateTo, dateFrom } = parseDateRange(selectedRange);

                setValue('timeRange', timeRange);
                if (!isCustom) {
                  setValue('dateTo', dateTo);
                  setValue('dateFrom', dateFrom);
                }

                setFilter({
                  ...filter,
                  timeRange,
                  dateFrom,
                  dateTo,
                });
              }}
            />
            {values.timeRange === DateRange.Custom && (
              <Flex gap="sm" align="center">
                <FlexItem grow={1}>
                  <ControlledDatePicker
                    name="dateFrom"
                    control={control}
                    placeholder="Date from"
                    onValueChange={(val) => {
                      setFilter({ ...filter, dateFrom: val });
                    }}
                  />
                </FlexItem>
                <FlexItem grow={1}>
                  <ControlledDatePicker
                    name="dateTo"
                    control={control}
                    placeholder="Date to"
                    onValueChange={(val) => {
                      setFilter({ ...filter, dateTo: val });
                    }}
                  />
                </FlexItem>
              </Flex>
            )}
          </Flex>
        </FlexItem>
      </Flex>
      <Spacing size="md" />
      {isNotSelectAgencyAdvertiser ? (
        <ReportingInfo message="Please select agency and advertiser to see the report" />
      ) : error ? (
        <Flex justify="center" align="center">
          <FlexItem width="60rem">
            <Flex direction="column" align="center">
              <Spacing size="8rem" />
              <Error error={getApiErrorMessage(error)} height="9rem" />
            </Flex>
          </FlexItem>
        </Flex>
      ) : isFetching ? (
        <Loading height="60rem" />
      ) : data?.data?.length ? (
        <>
          <ChartSummary {...filter} />
          <Spacing size="lg" />
          <Grid gap="md" align="start" columns={2}>
            <ChartSites {...filter} />
            <ChartCreatives {...filter} />
          </Grid>
        </>
      ) : (
        <>
          <Grid gap="md" columns={2}>
            <AccountSetup>
              <Text size="xl" weight={700}>
                Account Setup
              </Text>
              <Spacing size="lg" />
              <Grid flow="column" rows={3} gap="sm">
                {OVERVIEW_ACCOUNT_SETUPS.map((setup) => (
                  <Link key={setup.value} to={setup.url}>
                    <Flex align="center" gap="sm">
                      <Icon type="check" color="primaryDeep" />
                      <Text size="sm">{setup.label}</Text>
                    </Flex>
                  </Link>
                ))}
              </Grid>
            </AccountSetup>
            <Welcome>
              <Flex gap="xs">
                <Text size="xl" weight={700}>
                  Welcome,
                </Text>
                <Text size="xl" weight={700} color="primary">
                  {getUserName(user)}!
                </Text>
              </Flex>
              <Spacing size="lg" />
              <Text size="sm">{theme.description}</Text>
              <img src={imageUrl('welcome-bg.png')} alt="background" />
            </Welcome>
          </Grid>
          <Spacing size="md" />
          <Flex direction="column" justify="center" align="center" gap="md" height="40rem">
            <CreateCampaignImage src={imageUrl('notice.png')} alt="notice" />
            <Text size="lg" weight={600}>
              Start by creating an advertising campaign
            </Text>
            <Text size="sm">You don't have any campaigns running yet.</Text>
            {!isViewOnly && (
              <Link to="/activate/campaigns/new">
                <Button various="highlight" width="25rem" shadow>
                  CREATE A CAMPAIGN
                </Button>
              </Link>
            )}
          </Flex>
        </>
      )}
    </PageTemplate>
  );
};

const AccountSetup = styled.div`
  color: white;
  background: ${(props) => props.theme.color.primary};
  padding: 1.6rem 2.4rem;
  border-radius: 1rem;
  box-shadow: ${TOKENS.shadow.default};

  a {
    color: white;
  }
`;

const Welcome = styled.div`
  position: relative;
  background: white;
  padding: 1.6rem 2.4rem;
  border-radius: 1rem;
  box-shadow: ${TOKENS.shadow.default};
  padding-right: 18rem;

  img {
    position: absolute;
    width: 20rem;
    bottom: 0;
    right: 0;
  }
`;

const CreateCampaignImage = styled.img`
  width: 9rem;
  height: 9rem;
`;
