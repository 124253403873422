import { ControlledSelect, ControlledSelectOption, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useEffect, useMemo, useState } from 'react';
import { EditAdvertiserModal } from 'src/components/EditAdvertiserModal';
import { useController } from 'react-hook-form';
import { sortAlphabetically } from 'src/utils';

const { useAdvertisersQuery } = growthApi;

type AdvertiserSelectProps<T> = ControlledSelectProps<T> & {
  agencyId?: number;
  withAll?: boolean;
  disableAutoSelect?: boolean;
};

export const AdvertiserSelect = <T,>(props: AdvertiserSelectProps<T>) => {
  const { agencyId, withAll, disableAutoSelect, ...rest } = props;

  const { data, isFetching, refetch } = useAdvertisersQuery({ agency_id: agencyId });
  const [newAdvertiserModalIsOpen, setNewAdvertiserModalIsOpen] = useState<boolean>(false);

  const options = useMemo(() => {
    if (isFetching) {
      return [];
    }
    const dataOptions: ControlledSelectOption[] =
      data?.data?.map((advertiser) => ({
        label: `${advertiser.name} - ${advertiser.id}`,
        value: advertiser.id,
      })) || [];
    return withAll ? [{ label: 'All', value: null }, ...dataOptions] : dataOptions;
  }, [isFetching, withAll, data]);

  const {
    field: { onChange },
  } = useController(rest);

  useEffect(() => {
    if (disableAutoSelect) {
      return;
    }
    if ((options || []).filter((option) => Boolean(option.value)).length === 1) {
      // hack: fix the loading forever issue
      setTimeout(() => {
        onChange(options?.find((option) => Boolean(option.value)).value);
      }, 0);
    }
  }, [options, onChange, disableAutoSelect]);

  return (
    <>
      <ControlledSelect
        options={sortAlphabetically(options)}
        isLoading={isFetching}
        placeholder="Select advertiser"
        newLabel="New Advertiser"
        onNew={() => {
          setNewAdvertiserModalIsOpen(true);
        }}
        {...rest}
      />
      <EditAdvertiserModal
        isOpen={newAdvertiserModalIsOpen}
        onClose={() => setNewAdvertiserModalIsOpen(false)}
        onSuccess={(newId) => {
          setNewAdvertiserModalIsOpen(false);
          refetch();
          onChange(newId);
        }}
      />
    </>
  );
};
