import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useMemo } from 'react';
import { sortAlphabetically } from 'src/utils';

const { useCampaignsQuery } = growthApi;

type CampaignSelectProps<T> = ControlledSelectProps<T> & {
  agencyId?: number;
  advertiserId?: number;
  group?: string;
  withAll?: boolean;
};

export const ReportingCampaignSelectInHouse = <T,>(props: CampaignSelectProps<T>) => {
  const { agencyId, advertiserId, group, withAll, ...rest } = props;

  const { data, isFetching } = useCampaignsQuery({
    agency_id: agencyId,
    advertiser_id: advertiserId,
    group,
  });

  const options = useMemo(() => {
    if (isFetching) {
      return [];
    }
    const dataOptions =
      data?.data.map((item: any) => ({
        label: item.campaign_name,
        value: item.id,
      })) || [];
    return withAll ? [{ label: 'All', value: null }, ...dataOptions] : dataOptions;
  }, [isFetching, withAll, data]);

  return (
    <ControlledSelect
      options={sortAlphabetically(options)}
      isLoading={isFetching}
      placeholder="Select campaign"
      {...rest}
    />
  );
};
